/**

Override Bootstrap Components

1. Tabs
**. Misc
*/

@import "./../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "./../../../node_modules/@saleshandy/design-system/dist/assets/css/design-system.css";
@import "./custom-variable";
@import "../fonts/fontStyle";
@import "./scss/colors";
@import "./app.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
}

code {
  font-family: "Inter", sans-serif;
}

// 1. Tab /////////////////////////////////
@import "./scss/tab";
// 1. Tab /////////////////////////////////

////// Misc
.bs-mr-5 {
  margin-right: $mr-5 !important;
}

.bs-ml-5 {
  margin-left: $mr-5 !important;
}

.bs-mt-5 {
  margin-top: $mr-5 !important;
}

.bs-mb-5 {
  margin-bottom: $mr-5 !important;
}

.bs-mt-10 {
  margin-top: $mr-10 !important;
}

.bs-mr-10 {
  margin-right: $mr-10 !important;
}

.bs-ml-10 {
  margin-left: $mr-10 !important;
}

.bs-mb-10 {
  margin-bottom: $mr-10 !important;
}

.bs-mt-15 {
  margin-top: $mr-15 !important;
}

.bs-mr-15 {
  margin-right: $mr-15 !important;
}

.bs-ml-15 {
  margin-left: $mr-15 !important;
}

.bs-mb-15 {
  margin-bottom: $mr-15 !important;
}

.bs-mt-20 {
  margin-top: $mr-20 !important;
}
.bs-mr-20 {
  margin-right: $mr-20 !important;
}
.bs-mb-20 {
  margin-bottom: $mr-20 !important;
}
.bs-ml-20 {
  margin-left: $mr-20 !important;
}

.bs-mt-30 {
  margin-top: $mr-30 !important;
}
.bs-mr-30 {
  margin-right: $mr-30 !important;
}
.bs-mb-30 {
  margin-bottom: $mr-30 !important;
}
.bs-ml-30 {
  margin-left: $mr-30 !important;
}

.bs-no-margin {
  margin: $no-margin !important;
}

.divider {
  height: 1px;
  background: $gray-4;
  display: flex;
  margin: 1rem 0;
  width: 100%;
}

.no-shadow {
  box-shadow: none !important;
}

/*Skeleton loading start*/
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

/*Skeleton loading end*/

// header content style according to user plan subscription
.header-justify-content-center {
  justify-content: center;
}
.header-justify-content-end {
  justify-content: flex-end;
}

// Flex Wrap
.flex-flow-wrap {
  flex-flow: wrap;
}

// Button Primary Disable
.btn-primary-disable.btn-solid.btn-solid-primary:disabled {
  background: $blue-11;
  opacity: 0.35;
}

// Input
.input-field {
  input::placeholder {
    color: $gray-23;
  }
  .bs-input-label-prefix {
    color: $gray-15;
    font-weight: 600;
  }
  .bs-input-affix-wrapper {
    background: $gray-19 !important;
    .bs-input {
      background: $gray-19 !important;
    }
  }
}

// Avatar
.avatar {
  background: $blue-13;
  .bs-avatar-string {
    color: $blue-11;
    font-size: 0.625rem;
    font-weight: 500;
  }
  &:hover {
    cursor: pointer;
  }
}

.font-medium {
  font-weight: 500;
}

// Switch
.switchToggle input:checked + label {
  background: $blue-11;
}
.switchToggle input[type="checkbox"]:checked:disabled + label {
  background: $blue-20;
  opacity: 0.35;
}

// Toast
.toast {
  display: inherit !important;
  max-height: 3rem;
  max-width: 40rem;
}

// Header Style
.bs-tabs > .bs-tabs-nav > .bs-tabs-nav-wrap {
  margin-left: 13.75rem;
}

.bs-tabs > .bs-tabs-nav {
  box-shadow: 0px 0.25rem 0.25rem $gray-16;
}

.bs-tabs-large > .bs-tabs-nav .bs-tabs-tab {
  padding: 1.5625rem 0;
  font-weight: $font-weight-semibold;
  color: $gray-15;
}

.bs-tabs-large > .bs-tabs-nav .bs-tabs-tab-active {
  color: $blue-11;
}

// Loader
.loader {
  height: 90vh;
  .loading-spinner {
    height: 10rem;
  }
}

// Error Text
.error-text {
  color: $red-12;
}

// Link Text
.link-txt {
  color: $blue-11;
}

// **. Margin /////////////////////
.mr-10 {
  margin-right: 0.625rem !important;
}

// **. Input /////////////////////
.input-with-label {
  .bs-input-label-prefix {
    color: $gray-15;
    font-size: 0.75rem;
    font-weight: $font-weight-semibold;
  }
  .input-default {
    background: $gray-19;
    .bs-input {
      background: inherit;
    }
  }
}

.input-without-label {
  background: $gray-19;
  .bs-input {
    background: inherit;
  }
  .sh-search {
    font-size: 1rem;
    color: $gray-11;
  }
}

// **. Modal /////////////////////
.modal-no-header-border {
  .modal-header {
    border-bottom: 0 none;
  }

  .modal-footer {
    margin-left: 5%;
    margin-right: 6%;
    .bs-modal-footer-action-buttons {
      margin-right: -0.7rem;
    }
  }
}

.hover-link {
  &:hover {
    color: $blue-11;
  }
}

.table-list {
  height: calc(100vh - 171px) !important;
}
