.helpscout-button {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  padding: 0.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  color: $white;

  background: $blue-11;
  box-shadow: 0px 0px 0.25rem rgba(0, 0, 0, 0.25);

  border: none;

  position: absolute;
  right: 0.6875rem;
  bottom: 1.5rem;
}
