/**
App CSS Guide

*.  Home
*.  Main Navigation
*.  Settings
*.  Authenticate User (Login/Signup/Forgot)
*.  Sequence
**. Misc
*/

// Header
@import "./scss/header";

// No Search Found
@import "./scss/no-search-found";

// Auth
@import "../../components/auth/auth";

// Tab
@import "./scss/tab";

// Clients
@import "../../components/clients/clients";

// Teams
@import "../../components/teams/teams";

// billing
@import "../../components/billing/billing";

//suscription
@import "../../components/billing/subscription-plan-details";

// Password validation checklist
@import "../../shared/components/password-validation-checklist/password-validation-checklist.scss";

// Helpscout Beacon Button
@import "../../shared/components/helpscout-button/helpscout-button";

// Onboarding Modal
@import "../../shared/components/onboarding-modal/onboarding-modal";

// Purchase EV Credits Modal
@import "../../shared/components/purchase-ev-credits-modal/purchase-ev-credits";

// **. Misc ///////////////////////////////////
.pointer {
  cursor: pointer;
}

body {
  overflow-y: hidden;
}

a {
  color: $blue-11;
}

// Styling for headway badge
.HW_badge.HW_visible {
  background: $red-12;
}

.HW_badge.HW_softHidden {
  opacity: 0 !important;
}

.preheader-popover {
  .tooltip-inner {
    width: 100%;
    max-width: 100%;
    text-align: left;
  }
}

// **. Misc ///////////////////////////////////
.form-input-error-caption {
  font-size: 0.625rem;
}

.font-size-10 {
  font-size: 0.625rem;
  font-weight: 400;
  color: $gray-15;
}

.tooltip-width-auto {
  .tooltip-inner {
    width: max-content;
    max-width: max-content;
  }
}

.email-verify-alert {
  margin: 0;
  padding: 0.5rem;

  min-height: 2.25rem;

  background: #fffbeb;
  border-bottom: 1px solid #fde68a !important;
  box-sizing: border-box;

  justify-content: center !important;
  align-items: center;

  text-align: center;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #92400e;

  span span {
    text-decoration: underline;
    text-underline-offset: 0.125rem;
  }
}

.watch-video-modal {
  .modal-dialog {
    max-width: 38.625rem;
    .modal-header {
      height: 4rem;
      padding: 1.25rem 1.5rem;
      .modal-title {
        color: #1f2937;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.5rem;
      }
    }
    .modal-body {
      padding: 1.25rem 1.5rem;
    }
    .watch-video-modal-body {
      .watch-video-modal-text {
        display: flex;
        align-items: center;

        color: #4b5563;
        font-variant-numeric: stacked-fractions;
        font-feature-settings: "hlig" on, "dlig" on;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;

        margin-bottom: 1.5rem;
      }
    }
    .modal-footer {
      height: 4.125rem;
      padding: 1.0625rem 1.5rem;
    }
    .bs-modal-footer-action-buttons {
      margin: 0;

      button.btn-ghost {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 4.375rem;
        height: 2rem;

        margin: 0;
        padding: 0.375rem 1rem;
        border-color: #6b7280;

        color: #6b7280;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem;
      }
    }
  }
}

.skeleton-w-100 {
  .react-loading-skeleton {
    width: 100% !important;
  }
}

.confirmation-modal {
  .modal-dialog {
    width: 28.125rem !important;
    max-width: unset;
    min-height: auto;
    margin-top: 9rem;
    margin-bottom: 0;
  }
  .bs-modal-body {
    padding: 0 !important;
  }

  &--title {
    padding: 1.5rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 0.25rem 0.25rem 0 0;
    border: 0.125rem solid $gray-16;
    border-bottom: none;

    h1 {
      color: $blue-15;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: $font-weight-bold;
      margin: 0;
    }
  }

  &--description {
    padding: 0 1.5rem 1.25rem 1.5rem;

    display: flex;
    align-items: center;

    border: 0.125rem solid $gray-16;
    border-top: none;
    border-bottom: none;
  }

  &--title,
  &--description {
    p {
      color: $blue-15;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin: 0;

      span.bold {
        font-weight: $font-weight-semibold;
      }
    }
  }

  &--action {
    display: flex;
    align-items: center;

    padding: 0 1.5rem 1.5rem 1.5rem;

    border: 0.125rem solid $gray-16;
    border-top: none;
    border-bottom: none;
  }

  &--divider {
    display: flex;
    align-items: center;

    width: 25.125rem;
    height: 0.125rem;

    border-radius: 0.25rem;
    margin: 0 24px;

    background-color: $gray-16;
  }

  &--footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;

    height: 4.5rem;
    padding: 1.25rem 1.5rem;

    border-radius: 0 0 0.25rem 0.25rem;
    border: 0.125rem solid $gray-16;
    border-top: none;

    .shd-button--secondary {
      width: 6.25rem;
      color: $gray-15;
      border-color: $gray-13;

      &:hover,
      &:active,
      &:focus {
        color: #1d4ed8;
        background-color: transparent;
        border-color: #1d4ed8;
      }
    }
  }
}
