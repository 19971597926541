.billing {
  padding: 0 1.875rem;
}

.progress-bar-wrapper {
  height: 0.625rem;
  position: relative;
  overflow: hidden;
  border-radius: 1.875rem;
  background-color: #e5e7eb;
  span {
    display: block;
    height: 100%;
  }
  &__bar {
    @keyframes progressBar {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }
  }
}

.loader-accountusage {
  height: 174.4px !important;
  width: 1430.4px !important;
}
.billing-subscription-container {
  padding: 0rem 1.5rem 1rem 1.5rem;

  &__tab-content {
    overflow: auto;
    height: 70vh;
  }

  .bs-tabs > .bs-tabs-nav > .bs-tabs-nav-wrap {
    margin-left: 0px;
  }

  .bs-tabs > .bs-tabs-nav {
    border-bottom: 2px solid #f3f4f6;
    border-radius: 2px 2px 0px 0px;
    box-shadow: 0 0 0 0 transparent !important;
  }

  &__ev-credits {
    .ev-credits-container {
      height: 20rem !important;
      .card {
        box-shadow: 0px 2px 4px rgba(234, 237, 239, 0.65) !important;

        .card-body {
          display: flex;
          justify-content: space-between;
          padding: 0;
          .ev-credits-data {
            span {
              display: block;
            }
            .faq-page-link {
              color: $blue-11;
              text-decoration: underline;
            }
            .available-credits {
              color: $green-16;
              font-size: 1rem;
              font-weight: $font-weight-bold;
              margin-bottom: 0.7375rem;
            }
          }
          .purchase-credits-btn-wrap {
            .purchase-credits-btn.btn-solid-primary {
              background: $blue-11;
            }
          }
          .evc-loader {
            height: 4.7375rem;
            span {
              height: 1.25rem !important;
            }
          }
        }
      }
    }
  }
  .apu-loader {
    height: 8.3125rem;
  }
}

.subscription-container {
  overflow-y: scroll;
  height: calc(100vh - 9.375rem);

  &::-webkit-scrollbar {
    width: 0.4375rem;
    height: 0.4375rem;
    margin-right: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.25rem;
    background-color: #e5e7eb;
  }

  .subscription-info {
    min-height: 10.8125rem;
    padding: 1.25rem 1.25rem 1.25rem 2.375rem;

    border-radius: 0.25rem;
    border: 0.0625rem solid #eaedef;
    background: #fff;
    box-shadow: 0rem 0.125rem 0.25rem 0rem rgba(234, 237, 239, 0.65);

    &--header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-bottom: 0.375rem;

      p {
        margin: 0;
        color: #4b5563;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.25rem;
      }
    }
    &--plan {
      color: #1f2937;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.25rem;
      margin: 0;
      margin-bottom: 1.25rem;
    }
    &--details {
      display: flex;
      max-width: 47.75rem;
      gap: 8.125rem;

      .subscription-detail {
        p {
          color: #4b5563;
          font-size: 0.625rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.25rem;

          margin: 0;
          margin-bottom: 0.3125rem;
        }
        h2 {
          color: #111827;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.25rem;
        }
      }
    }
  }
}
