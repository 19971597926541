@import 'fontVariables';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot');
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot')
      format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg##{$icomoon-font-family}')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='sh-'],
[class*=' sh-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sh-prospects {
  &:before {
    content: $sh-prospects;
  }
}
.sh-curly-braces {
  &:before {
    content: $sh-curly-braces;
  }
}
.sh-warning-hexagone {
  &:before {
    content: $sh-warning-hexagone;
  }
}
.sh-warning {
  &:before {
    content: $sh-warning;
  }
}
.sh-alarm {
  &:before {
    content: $sh-alarm;
  }
}
.sh-battery {
  &:before {
    content: $sh-battery;
  }
}
.sh-battery-empty {
  &:before {
    content: $sh-battery-empty;
  }
}
.sh-battery-full {
  &:before {
    content: $sh-battery-full;
  }
}
.sh-bell {
  &:before {
    content: $sh-bell;
  }
}
.sh-bolt {
  &:before {
    content: $sh-bolt;
  }
}
.sh-bulb {
  &:before {
    content: $sh-bulb;
  }
}
.sh-calendar {
  &:before {
    content: $sh-calendar;
  }
}
.sh-calendar-dates {
  &:before {
    content: $sh-calendar-dates;
  }
}
.sh-calendar-due {
  &:before {
    content: $sh-calendar-due;
  }
}
.sh-calendar-next {
  &:before {
    content: $sh-calendar-next;
  }
}
.sh-calendar-today {
  &:before {
    content: $sh-calendar-today;
  }
}
.sh-calendar-two {
  &:before {
    content: $sh-calendar-two;
  }
}
.sh-danger {
  &:before {
    content: $sh-danger;
  }
}
.sh-info {
  &:before {
    content: $sh-info;
  }
}
.sh-phone {
  &:before {
    content: $sh-phone;
  }
}
.sh-caret-up {
  &:before {
    content: $sh-caret-up;
  }
}
.sh-caret-down {
  &:before {
    content: $sh-caret-down;
  }
}
.sh-caret-right {
  &:before {
    content: $sh-caret-right;
  }
}
.sh-caret-left {
  &:before {
    content: $sh-caret-left;
  }
}
.sh-arrow-align-h {
  &:before {
    content: $sh-arrow-align-h;
  }
}
.sh-arrow-align-v {
  &:before {
    content: $sh-arrow-align-v;
  }
}
.sh-arrow-bottom-left {
  &:before {
    content: $sh-arrow-bottom-left;
  }
}
.sh-arrow-bottom-left-o {
  &:before {
    content: $sh-arrow-bottom-left-o;
  }
}
.sh-arrow-bottom-left-r {
  &:before {
    content: $sh-arrow-bottom-left-r;
  }
}
.sh-arrow-bottom-right {
  &:before {
    content: $sh-arrow-bottom-right;
  }
}
.sh-arrow-bottom-right-o {
  &:before {
    content: $sh-arrow-bottom-right-o;
  }
}
.sh-arrow-bottom-right-r {
  &:before {
    content: $sh-arrow-bottom-right-r;
  }
}
.sh-arrow-down {
  &:before {
    content: $sh-arrow-down;
  }
}
.sh-arrow-down-o {
  &:before {
    content: $sh-arrow-down-o;
  }
}
.sh-arrow-down-r {
  &:before {
    content: $sh-arrow-down-r;
  }
}
.sh-arrow-left {
  &:before {
    content: $sh-arrow-left;
  }
}
.sh-arrow-left-o {
  &:before {
    content: $sh-arrow-left-o;
  }
}
.sh-arrow-left-r {
  &:before {
    content: $sh-arrow-left-r;
  }
}
.sh-arrow-long-down {
  &:before {
    content: $sh-arrow-long-down;
  }
}
.sh-arrow-long-down-c {
  &:before {
    content: $sh-arrow-long-down-c;
  }
}
.sh-arrow-long-down-e {
  &:before {
    content: $sh-arrow-long-down-e;
  }
}
.sh-arrow-long-down-l {
  &:before {
    content: $sh-arrow-long-down-l;
  }
}
.sh-arrow-long-down-r {
  &:before {
    content: $sh-arrow-long-down-r;
  }
}
.sh-arrow-long-left {
  &:before {
    content: $sh-arrow-long-left;
  }
}
.sh-arrow-long-left-c {
  &:before {
    content: $sh-arrow-long-left-c;
  }
}
.sh-arrow-long-left-e {
  &:before {
    content: $sh-arrow-long-left-e;
  }
}
.sh-arrow-long-left-l {
  &:before {
    content: $sh-arrow-long-left-l;
  }
}
.sh-arrow-long-left-r {
  &:before {
    content: $sh-arrow-long-left-r;
  }
}
.sh-arrow-long-right {
  &:before {
    content: $sh-arrow-long-right;
  }
}
.sh-arrow-long-right-c {
  &:before {
    content: $sh-arrow-long-right-c;
  }
}
.sh-arrow-long-right-e {
  &:before {
    content: $sh-arrow-long-right-e;
  }
}
.sh-arrow-long-right-l {
  &:before {
    content: $sh-arrow-long-right-l;
  }
}
.sh-arrow-long-right-r {
  &:before {
    content: $sh-arrow-long-right-r;
  }
}
.sh-arrow-long-up {
  &:before {
    content: $sh-arrow-long-up;
  }
}
.sh-arrow-long-up-c {
  &:before {
    content: $sh-arrow-long-up-c;
  }
}
.sh-arrow-long-up-e {
  &:before {
    content: $sh-arrow-long-up-e;
  }
}
.sh-arrow-long-up-l {
  &:before {
    content: $sh-arrow-long-up-l;
  }
}
.sh-arrow-long-up-r {
  &:before {
    content: $sh-arrow-long-up-r;
  }
}
.sh-arrow-right {
  &:before {
    content: $sh-arrow-right;
  }
}
.sh-arrow-right-o {
  &:before {
    content: $sh-arrow-right-o;
  }
}
.sh-arrow-right-r {
  &:before {
    content: $sh-arrow-right-r;
  }
}
.sh-arrows-breake-h {
  &:before {
    content: $sh-arrows-breake-h;
  }
}
.sh-arrows-breake-v {
  &:before {
    content: $sh-arrows-breake-v;
  }
}
.sh-arrows-exchange {
  &:before {
    content: $sh-arrows-exchange;
  }
}
.sh-arrows-exchange-alt {
  &:before {
    content: $sh-arrows-exchange-alt;
  }
}
.sh-arrows-exchange-alt-v {
  &:before {
    content: $sh-arrows-exchange-alt-v;
  }
}
.sh-arrows-exchange-v {
  &:before {
    content: $sh-arrows-exchange-v;
  }
}
.sh-arrows-expand-down-left {
  &:before {
    content: $sh-arrows-expand-down-left;
  }
}
.sh-arrows-expand-down-right {
  &:before {
    content: $sh-arrows-expand-down-right;
  }
}
.sh-arrows-expand-left {
  &:before {
    content: $sh-arrows-expand-left;
  }
}
.sh-arrows-expand-left-alt {
  &:before {
    content: $sh-arrows-expand-left-alt;
  }
}
.sh-arrows-expand-right {
  &:before {
    content: $sh-arrows-expand-right;
  }
}
.sh-arrows-expand-right-alt {
  &:before {
    content: $sh-arrows-expand-right-alt;
  }
}
.sh-arrows-expand-up-left {
  &:before {
    content: $sh-arrows-expand-up-left;
  }
}
.sh-arrows-expand-up-right {
  &:before {
    content: $sh-arrows-expand-up-right;
  }
}
.sh-arrows-h {
  &:before {
    content: $sh-arrows-h;
  }
}
.sh-arrows-h-alt {
  &:before {
    content: $sh-arrows-h-alt;
  }
}
.sh-arrows-merge-alt-h {
  &:before {
    content: $sh-arrows-merge-alt-h;
  }
}
.sh-arrows-merge-alt-v {
  &:before {
    content: $sh-arrows-merge-alt-v;
  }
}
.sh-arrows-scroll-h {
  &:before {
    content: $sh-arrows-scroll-h;
  }
}
.sh-arrows-scroll-v {
  &:before {
    content: $sh-arrows-scroll-v;
  }
}
.sh-arrows-shrink-h {
  &:before {
    content: $sh-arrows-shrink-h;
  }
}
.sh-arrows-shrink-v {
  &:before {
    content: $sh-arrows-shrink-v;
  }
}
.sh-arrows-v {
  &:before {
    content: $sh-arrows-v;
  }
}
.sh-arrows-v-alt {
  &:before {
    content: $sh-arrows-v-alt;
  }
}
.sh-arrow-top-left {
  &:before {
    content: $sh-arrow-top-left;
  }
}
.sh-arrow-top-left-o {
  &:before {
    content: $sh-arrow-top-left-o;
  }
}
.sh-arrow-top-left-r {
  &:before {
    content: $sh-arrow-top-left-r;
  }
}
.sh-arrow-top-right {
  &:before {
    content: $sh-arrow-top-right;
  }
}
.sh-arrow-top-right-o {
  &:before {
    content: $sh-arrow-top-right-o;
  }
}
.sh-arrow-top-right-r {
  &:before {
    content: $sh-arrow-top-right-r;
  }
}
.sh-arrow-up {
  &:before {
    content: $sh-arrow-up;
  }
}
.sh-arrow-up-o {
  &:before {
    content: $sh-arrow-up-o;
  }
}
.sh-arrow-up-r {
  &:before {
    content: $sh-arrow-up-r;
  }
}
.sh-check {
  &:before {
    content: $sh-check;
  }
}
.sh-check-o {
  &:before {
    content: $sh-check-o;
  }
}
.sh-check-r {
  &:before {
    content: $sh-check-r;
  }
}
.sh-compress {
  &:before {
    content: $sh-compress;
  }
}
.sh-compress-left {
  &:before {
    content: $sh-compress-left;
  }
}
.sh-compress-right {
  &:before {
    content: $sh-compress-right;
  }
}
.sh-compress-v {
  &:before {
    content: $sh-compress-v;
  }
}
.sh-corner-double-down-left {
  &:before {
    content: $sh-corner-double-down-left;
  }
}
.sh-corner-double-down-right {
  &:before {
    content: $sh-corner-double-down-right;
  }
}
.sh-corner-double-left-down {
  &:before {
    content: $sh-corner-double-left-down;
  }
}
.sh-corner-double-left-up {
  &:before {
    content: $sh-corner-double-left-up;
  }
}
.sh-corner-double-right-down {
  &:before {
    content: $sh-corner-double-right-down;
  }
}
.sh-corner-double-right-up {
  &:before {
    content: $sh-corner-double-right-up;
  }
}
.sh-corner-double-up-left {
  &:before {
    content: $sh-corner-double-up-left;
  }
}
.sh-corner-double-up-right {
  &:before {
    content: $sh-corner-double-up-right;
  }
}
.sh-corner-down-left {
  &:before {
    content: $sh-corner-down-left;
  }
}
.sh-corner-down-right {
  &:before {
    content: $sh-corner-down-right;
  }
}
.sh-corner-left-down {
  &:before {
    content: $sh-corner-left-down;
  }
}
.sh-corner-left-up {
  &:before {
    content: $sh-corner-left-up;
  }
}
.sh-corner-right-down {
  &:before {
    content: $sh-corner-right-down;
  }
}
.sh-corner-right-up {
  &:before {
    content: $sh-corner-right-up;
  }
}
.sh-corner-up-left {
  &:before {
    content: $sh-corner-up-left;
  }
}
.sh-corner-up-right {
  &:before {
    content: $sh-corner-up-right;
  }
}
.sh-enter {
  &:before {
    content: $sh-enter;
  }
}
.sh-expand {
  &:before {
    content: $sh-expand;
  }
}
.sh-export {
  &:before {
    content: $sh-export;
  }
}
.sh-import {
  &:before {
    content: $sh-import;
  }
}
.sh-internal {
  &:before {
    content: $sh-internal;
  }
}
.sh-merge-horizontal {
  &:before {
    content: $sh-merge-horizontal;
  }
}
.sh-merge-vertical {
  &:before {
    content: $sh-merge-vertical;
  }
}
.sh-minimize {
  &:before {
    content: $sh-minimize;
  }
}
.sh-minimize-alt {
  &:before {
    content: $sh-minimize-alt;
  }
}
.sh-move-down {
  &:before {
    content: $sh-move-down;
  }
}
.sh-move-left {
  &:before {
    content: $sh-move-left;
  }
}
.sh-move-right {
  &:before {
    content: $sh-move-right;
  }
}
.sh-move-task {
  &:before {
    content: $sh-move-task;
  }
}
.sh-move-up {
  &:before {
    content: $sh-move-up;
  }
}
.sh-push-down {
  &:before {
    content: $sh-push-down;
  }
}
.sh-push-left {
  &:before {
    content: $sh-push-left;
  }
}
.sh-push-right {
  &:before {
    content: $sh-push-right;
  }
}
.sh-push-up {
  &:before {
    content: $sh-push-up;
  }
}
.sh-redo {
  &:before {
    content: $sh-redo;
  }
}
.sh-repeat {
  &:before {
    content: $sh-repeat;
  }
}
.sh-scroll-h {
  &:before {
    content: $sh-scroll-h;
  }
}
.sh-scroll-v {
  &:before {
    content: $sh-scroll-v;
  }
}
.sh-select {
  &:before {
    content: $sh-select;
  }
}
.sh-select-o {
  &:before {
    content: $sh-select-o;
  }
}
.sh-select-r {
  &:before {
    content: $sh-select-r;
  }
}
.sh-swap {
  &:before {
    content: $sh-swap;
  }
}
.sh-swap-vertical {
  &:before {
    content: $sh-swap-vertical;
  }
}
.sh-sync {
  &:before {
    content: $sh-sync;
  }
}
.sh-trending {
  &:before {
    content: $sh-trending;
  }
}
.sh-trending-down {
  &:before {
    content: $sh-trending-down;
  }
}
.sh-undo {
  &:before {
    content: $sh-undo;
  }
}
.sh-abstract {
  &:before {
    content: $sh-abstract;
  }
}
.sh-adidas {
  &:before {
    content: $sh-adidas;
  }
}
.sh-atlasian .path1 {
  &:before {
    content: $sh-atlasian-path1;
    color: rgb(0, 0, 0);
    opacity: 0.8;
  }
}
.sh-atlasian .path2 {
  &:before {
    content: $sh-atlasian-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-bmw {
  &:before {
    content: $sh-bmw;
  }
}
.sh-brackets {
  &:before {
    content: $sh-brackets;
  }
}
.sh-chanel {
  &:before {
    content: $sh-chanel;
  }
}
.sh-circleci {
  &:before {
    content: $sh-circleci;
  }
}
.sh-code-climate {
  &:before {
    content: $sh-code-climate;
  }
}
.sh-comedy-central {
  &:before {
    content: $sh-comedy-central;
  }
}
.sh-crowdfire {
  &:before {
    content: $sh-crowdfire;
  }
}
.sh-designmodo {
  &:before {
    content: $sh-designmodo;
  }
}
.sh-digitalocean {
  &:before {
    content: $sh-digitalocean;
  }
}
.sh-dolby {
  &:before {
    content: $sh-dolby;
  }
}
.sh-dribbble {
  &:before {
    content: $sh-dribbble;
  }
}
.sh-eventbrite {
  &:before {
    content: $sh-eventbrite;
  }
}
.sh-facebook {
  &:before {
    content: $sh-facebook;
  }
}
.sh-figma {
  &:before {
    content: $sh-figma;
  }
}
.sh-framer .path1 {
  &:before {
    content: $sh-framer-path1;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-framer .path2 {
  &:before {
    content: $sh-framer-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-ghost {
  &:before {
    content: $sh-ghost;
  }
}
.sh-gitter {
  &:before {
    content: $sh-gitter;
  }
}
.sh-google {
  &:before {
    content: $sh-google;
  }
}
.sh-google-tasks {
  &:before {
    content: $sh-google-tasks;
  }
}
.sh-hello {
  &:before {
    content: $sh-hello;
  }
}
.sh-if-design {
  &:before {
    content: $sh-if-design;
  }
}
.sh-indie-hackers {
  &:before {
    content: $sh-indie-hackers;
  }
}
.sh-instagram {
  &:before {
    content: $sh-instagram;
  }
}
.sh-lastpass {
  &:before {
    content: $sh-lastpass;
  }
}
.sh-linear {
  &:before {
    content: $sh-linear;
  }
}
.sh-maze {
  &:before {
    content: $sh-maze;
  }
}
.sh-microbit {
  &:before {
    content: $sh-microbit;
  }
}
.sh-microsoft {
  &:before {
    content: $sh-microsoft;
  }
}
.sh-monday {
  &:before {
    content: $sh-monday;
  }
}
.sh-npm {
  &:before {
    content: $sh-npm;
  }
}
.sh-oculus {
  &:before {
    content: $sh-oculus;
  }
}
.sh-overflow .path1 {
  &:before {
    content: $sh-overflow-path1;
    color: rgb(0, 0, 0);
    opacity: 0.2;
  }
}
.sh-overflow .path2 {
  &:before {
    content: $sh-overflow-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.sh-overflow .path3 {
  &:before {
    content: $sh-overflow-path3;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-pacman {
  &:before {
    content: $sh-pacman;
  }
}
.sh-patreon .path1 {
  &:before {
    content: $sh-patreon-path1;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-patreon .path2 {
  &:before {
    content: $sh-patreon-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-paypal {
  &:before {
    content: $sh-paypal;
  }
}
.sh-pexels {
  &:before {
    content: $sh-pexels;
  }
}
.sh-pocket {
  &:before {
    content: $sh-pocket;
  }
}
.sh-pokemon {
  &:before {
    content: $sh-pokemon;
  }
}
.sh-product-hunt {
  &:before {
    content: $sh-product-hunt;
  }
}
.sh-serverless {
  &:before {
    content: $sh-serverless;
  }
}
.sh-shutterstock {
  &:before {
    content: $sh-shutterstock;
  }
}
.sh-sketch {
  &:before {
    content: $sh-sketch;
  }
}
.sh-slack {
  &:before {
    content: $sh-slack;
  }
}
.sh-spectrum {
  &:before {
    content: $sh-spectrum;
  }
}
.sh-square {
  &:before {
    content: $sh-square;
  }
}
.sh-stark {
  &:before {
    content: $sh-stark;
  }
}
.sh-studio {
  &:before {
    content: $sh-studio;
  }
}
.sh-sweden {
  &:before {
    content: $sh-sweden;
  }
}
.sh-swiss {
  &:before {
    content: $sh-swiss;
  }
}
.sh-tally {
  &:before {
    content: $sh-tally;
  }
}
.sh-trello {
  &:before {
    content: $sh-trello;
  }
}
.sh-twilio {
  &:before {
    content: $sh-twilio;
  }
}
.sh-twitter {
  &:before {
    content: $sh-twitter;
  }
}
.sh-unfold {
  &:before {
    content: $sh-unfold;
  }
}
.sh-unsplash {
  &:before {
    content: $sh-unsplash;
  }
}
.sh-userlane {
  &:before {
    content: $sh-userlane;
  }
}
.sh-windows {
  &:before {
    content: $sh-windows;
  }
}
.sh-youtube {
  &:before {
    content: $sh-youtube;
  }
}
.sh-zeit {
  &:before {
    content: $sh-zeit;
  }
}
.sh-filter {
  &:before {
    content: $sh-filter;
  }
}
.sh-add {
  &:before {
    content: $sh-add;
  }
}
.sh-add-r {
  &:before {
    content: $sh-add-r;
  }
}
.sh-assign {
  &:before {
    content: $sh-assign;
  }
}
.sh-backspace {
  &:before {
    content: $sh-backspace;
  }
}
.sh-bookmark {
  &:before {
    content: $sh-bookmark;
  }
}
.sh-captions {
  &:before {
    content: $sh-captions;
  }
}
.sh-close-o {
  &:before {
    content: $sh-close-o;
  }
}
.sh-close-r {
  &:before {
    content: $sh-close-r;
  }
}
.sh-feed .path1 {
  &:before {
    content: $sh-feed-path1;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-feed .path2 {
  &:before {
    content: $sh-feed-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-feed .path3 {
  &:before {
    content: $sh-feed-path3;
    margin-left: -1em;
    color: rgb(0, 0, 0);
    opacity: 0.8;
  }
}
.sh-feed .path4 {
  &:before {
    content: $sh-feed-path4;
    margin-left: -1em;
    color: rgb(0, 0, 0);
    opacity: 0.8;
  }
}
.sh-feed .path5 {
  &:before {
    content: $sh-feed-path5;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-feed .path6 {
  &:before {
    content: $sh-feed-path6;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-flag {
  &:before {
    content: $sh-flag;
  }
}
.sh-inbox {
  &:before {
    content: $sh-inbox;
  }
}
.sh-lock {
  &:before {
    content: $sh-lock;
  }
}
.sh-lock-unlock {
  &:before {
    content: $sh-lock-unlock;
  }
}
.sh-log-in {
  &:before {
    content: $sh-log-in;
  }
}
.sh-log-off {
  &:before {
    content: $sh-log-off;
  }
}
.sh-log-out {
  &:before {
    content: $sh-log-out;
  }
}
.sh-menu {
  &:before {
    content: $sh-menu;
  }
}
.sh-menu-left {
  &:before {
    content: $sh-menu-left;
  }
}
.sh-menu-right {
  &:before {
    content: $sh-menu-right;
  }
}
.sh-more {
  &:before {
    content: $sh-more;
  }
}
.sh-more-alt {
  &:before {
    content: $sh-more-alt;
  }
}
.sh-more-o {
  &:before {
    content: $sh-more-o;
  }
}
.sh-more-r {
  &:before {
    content: $sh-more-r;
  }
}
.sh-more-vertical {
  &:before {
    content: $sh-more-vertical;
  }
}
.sh-more-vertical-alt {
  &:before {
    content: $sh-more-vertical-alt;
  }
}
.sh-more-vertical-o {
  &:before {
    content: $sh-more-vertical-o;
  }
}
.sh-more-vertical-r {
  &:before {
    content: $sh-more-vertical-r;
  }
}
.sh-play-pause {
  &:before {
    content: $sh-play-pause;
  }
}
.sh-play-pause-o {
  &:before {
    content: $sh-play-pause-o;
  }
}
.sh-play-pause-r {
  &:before {
    content: $sh-play-pause-r;
  }
}
.sh-play-stop {
  &:before {
    content: $sh-play-stop;
  }
}
.sh-play-stop-o {
  &:before {
    content: $sh-play-stop-o;
  }
}
.sh-play-stop-r {
  &:before {
    content: $sh-play-stop-r;
  }
}
.sh-printer {
  &:before {
    content: $sh-printer;
  }
}
.sh-read {
  &:before {
    content: $sh-read;
  }
}
.sh-share {
  &:before {
    content: $sh-share;
  }
}
.sh-shortcut {
  &:before {
    content: $sh-shortcut;
  }
}
.sh-size {
  &:before {
    content: $sh-size;
  }
}
.sh-software-download {
  &:before {
    content: $sh-software-download;
  }
}
.sh-software-upload {
  &:before {
    content: $sh-software-upload;
  }
}
.sh-sort-az {
  &:before {
    content: $sh-sort-az;
  }
}
.sh-sort-za {
  &:before {
    content: $sh-sort-za;
  }
}
.sh-square1 {
  &:before {
    content: $sh-square1;
  }
}
.sh-toggle-off {
  &:before {
    content: $sh-toggle-off;
  }
}
.sh-toggle-on {
  &:before {
    content: $sh-toggle-on;
  }
}
.sh-toggle-square {
  &:before {
    content: $sh-toggle-square;
  }
}
.sh-toggle-square-off {
  &:before {
    content: $sh-toggle-square-off;
  }
}
.sh-chevron-double-down {
  &:before {
    content: $sh-chevron-double-down;
  }
}
.sh-chevron-double-down-o {
  &:before {
    content: $sh-chevron-double-down-o;
  }
}
.sh-chevron-double-down-r {
  &:before {
    content: $sh-chevron-double-down-r;
  }
}
.sh-chevron-double-left {
  &:before {
    content: $sh-chevron-double-left;
  }
}
.sh-chevron-double-left-o {
  &:before {
    content: $sh-chevron-double-left-o;
  }
}
.sh-chevron-double-left-r {
  &:before {
    content: $sh-chevron-double-left-r;
  }
}
.sh-chevron-double-right {
  &:before {
    content: $sh-chevron-double-right;
  }
}
.sh-chevron-double-right-o {
  &:before {
    content: $sh-chevron-double-right-o;
  }
}
.sh-chevron-double-right-r {
  &:before {
    content: $sh-chevron-double-right-r;
  }
}
.sh-chevron-double-up {
  &:before {
    content: $sh-chevron-double-up;
  }
}
.sh-chevron-double-up-o {
  &:before {
    content: $sh-chevron-double-up-o;
  }
}
.sh-chevron-double-up-r {
  &:before {
    content: $sh-chevron-double-up-r;
  }
}
.sh-chevron-down {
  &:before {
    content: $sh-chevron-down;
  }
}
.sh-chevron-down-o {
  &:before {
    content: $sh-chevron-down-o;
  }
}
.sh-chevron-down-r {
  &:before {
    content: $sh-chevron-down-r;
  }
}
.sh-chevron-left {
  &:before {
    content: $sh-chevron-left;
  }
}
.sh-chevron-left-o {
  &:before {
    content: $sh-chevron-left-o;
  }
}
.sh-chevron-left-r {
  &:before {
    content: $sh-chevron-left-r;
  }
}
.sh-chevron-right {
  &:before {
    content: $sh-chevron-right;
  }
}
.sh-chevron-right-o {
  &:before {
    content: $sh-chevron-right-o;
  }
}
.sh-chevron-right-r {
  &:before {
    content: $sh-chevron-right-r;
  }
}
.sh-chevron-up {
  &:before {
    content: $sh-chevron-up;
  }
}
.sh-chevron-up-o {
  &:before {
    content: $sh-chevron-up-o;
  }
}
.sh-chevron-up-r {
  &:before {
    content: $sh-chevron-up-r;
  }
}
.sh-push-chevron-down {
  &:before {
    content: $sh-push-chevron-down;
  }
}
.sh-push-chevron-down-o {
  &:before {
    content: $sh-push-chevron-down-o;
  }
}
.sh-push-chevron-down-r {
  &:before {
    content: $sh-push-chevron-down-r;
  }
}
.sh-push-chevron-left {
  &:before {
    content: $sh-push-chevron-left;
  }
}
.sh-push-chevron-left-o {
  &:before {
    content: $sh-push-chevron-left-o;
  }
}
.sh-push-chevron-left-r {
  &:before {
    content: $sh-push-chevron-left-r;
  }
}
.sh-push-chevron-right {
  &:before {
    content: $sh-push-chevron-right;
  }
}
.sh-push-chevron-right-o {
  &:before {
    content: $sh-push-chevron-right-o;
  }
}
.sh-push-chevron-right-r {
  &:before {
    content: $sh-push-chevron-right-r;
  }
}
.sh-push-chevron-up {
  &:before {
    content: $sh-push-chevron-up;
  }
}
.sh-push-chevron-up-o {
  &:before {
    content: $sh-push-chevron-up-o;
  }
}
.sh-push-chevron-up-r {
  &:before {
    content: $sh-push-chevron-up-r;
  }
}
.sh-code {
  &:before {
    content: $sh-code;
  }
}
.sh-code-slash {
  &:before {
    content: $sh-code-slash;
  }
}
.sh-c-plus-plus {
  &:before {
    content: $sh-c-plus-plus;
  }
}
.sh-git-branch {
  &:before {
    content: $sh-git-branch;
  }
}
.sh-git-commit {
  &:before {
    content: $sh-git-commit;
  }
}
.sh-git-fork {
  &:before {
    content: $sh-git-fork;
  }
}
.sh-git-pull {
  &:before {
    content: $sh-git-pull;
  }
}
.sh-hashtag {
  &:before {
    content: $sh-hashtag;
  }
}
.sh-remote {
  &:before {
    content: $sh-remote;
  }
}
.sh-terminal {
  &:before {
    content: $sh-terminal;
  }
}
.sh-align-bottom .path1 {
  &:before {
    content: $sh-align-bottom-path1;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-align-bottom .path2 {
  &:before {
    content: $sh-align-bottom-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-align-bottom .path3 {
  &:before {
    content: $sh-align-bottom-path3;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-align-center .path1 {
  &:before {
    content: $sh-align-center-path1;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-align-center .path2 {
  &:before {
    content: $sh-align-center-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-align-left .path1 {
  &:before {
    content: $sh-align-left-path1;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-align-left .path2 {
  &:before {
    content: $sh-align-left-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-align-left .path3 {
  &:before {
    content: $sh-align-left-path3;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-align-middle .path1 {
  &:before {
    content: $sh-align-middle-path1;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-align-middle .path2 {
  &:before {
    content: $sh-align-middle-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-align-right .path1 {
  &:before {
    content: $sh-align-right-path1;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-align-right .path2 {
  &:before {
    content: $sh-align-right-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-align-right .path3 {
  &:before {
    content: $sh-align-right-path3;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-align-top .path1 {
  &:before {
    content: $sh-align-top-path1;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-align-top .path2 {
  &:before {
    content: $sh-align-top-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-align-top .path3 {
  &:before {
    content: $sh-align-top-path3;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-attachment {
  &:before {
    content: $sh-attachment;
  }
}
.sh-board {
  &:before {
    content: $sh-board;
  }
}
.sh-clipboard {
  &:before {
    content: $sh-clipboard;
  }
}
.sh-copy {
  &:before {
    content: $sh-copy;
  }
}
.sh-crop {
  &:before {
    content: $sh-crop;
  }
}
.sh-details-less {
  &:before {
    content: $sh-details-less;
  }
}
.sh-details-more {
  &:before {
    content: $sh-details-more;
  }
}
.sh-display-flex {
  &:before {
    content: $sh-display-flex;
  }
}
.sh-display-fullwidth {
  &:before {
    content: $sh-display-fullwidth;
  }
}
.sh-display-grid {
  &:before {
    content: $sh-display-grid;
  }
}
.sh-display-spacing {
  &:before {
    content: $sh-display-spacing;
  }
}
.sh-distribute-horizontal {
  &:before {
    content: $sh-distribute-horizontal;
    color: #000;
  }
}
.sh-duplicate {
  &:before {
    content: $sh-duplicate;
  }
}
.sh-format-bold {
  &:before {
    content: $sh-format-bold;
  }
}
.sh-format-center {
  &:before {
    content: $sh-format-center;
  }
}
.sh-format-color {
  &:before {
    content: $sh-format-color;
  }
}
.sh-format-heading {
  &:before {
    content: $sh-format-heading;
  }
}
.sh-format-indent-decrease {
  &:before {
    content: $sh-format-indent-decrease;
  }
}
.sh-format-indent-increase {
  &:before {
    content: $sh-format-indent-increase;
  }
}
.sh-format-italic {
  &:before {
    content: $sh-format-italic;
  }
}
.sh-format-justify {
  &:before {
    content: $sh-format-justify;
  }
}
.sh-format-left {
  &:before {
    content: $sh-format-left;
  }
}
.sh-format-line-height {
  &:before {
    content: $sh-format-line-height;
  }
}
.sh-format-right {
  &:before {
    content: $sh-format-right;
  }
}
.sh-format-separator .path1 {
  &:before {
    content: $sh-format-separator-path1;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-format-separator .path2 {
  &:before {
    content: $sh-format-separator-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-format-separator .path3 {
  &:before {
    content: $sh-format-separator-path3;
    margin-left: -1em;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-format-separator .path4 {
  &:before {
    content: $sh-format-separator-path4;
    margin-left: -1em;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-format-separator .path5 {
  &:before {
    content: $sh-format-separator-path5;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-format-slash {
  &:before {
    content: $sh-format-slash;
  }
}
.sh-format-strike {
  &:before {
    content: $sh-format-strike;
  }
}
.sh-format-text {
  &:before {
    content: $sh-format-text;
  }
}
.sh-format-underline {
  &:before {
    content: $sh-format-underline;
  }
}
.sh-format-uppercase {
  &:before {
    content: $sh-format-uppercase;
  }
}
.sh-layout-grid {
  &:before {
    content: $sh-layout-grid;
  }
}
.sh-layout-grid-small {
  &:before {
    content: $sh-layout-grid-small;
  }
}
.sh-layout-list {
  &:before {
    content: $sh-layout-list;
  }
}
.sh-layout-pin {
  &:before {
    content: $sh-layout-pin;
  }
}
.sh-link {
  &:before {
    content: $sh-link;
  }
}
.sh-list {
  &:before {
    content: $sh-list;
  }
}
.sh-math-divide {
  &:before {
    content: $sh-math-divide;
  }
}
.sh-math-equal {
  &:before {
    content: $sh-math-equal;
  }
}
.sh-math-minus {
  &:before {
    content: $sh-math-minus;
  }
}
.sh-math-percent {
  &:before {
    content: $sh-math-percent;
  }
}
.sh-math-plus {
  &:before {
    content: $sh-math-plus;
  }
}
.sh-menu-boxed {
  &:before {
    content: $sh-menu-boxed;
  }
}
.sh-menu-left-alt {
  &:before {
    content: $sh-menu-left-alt;
  }
}
.sh-menu-right-alt {
  &:before {
    content: $sh-menu-right-alt;
  }
}
.sh-menu-round {
  &:before {
    content: $sh-menu-round;
  }
}
.sh-notes {
  &:before {
    content: $sh-notes;
  }
}
.sh-pin {
  &:before {
    content: $sh-pin;
  }
}
.sh-pin-alt {
  &:before {
    content: $sh-pin-alt;
  }
}
.sh-pin-bottom {
  &:before {
    content: $sh-pin-bottom;
  }
}
.sh-pin-top {
  &:before {
    content: $sh-pin-top;
  }
}
.sh-quote {
  &:before {
    content: $sh-quote;
  }
}
.sh-quote-o {
  &:before {
    content: $sh-quote-o;
  }
}
.sh-ruler {
  &:before {
    content: $sh-ruler;
  }
}
.sh-smile {
  &:before {
    content: $sh-smile;
  }
}
.sh-smile-mouth-open {
  &:before {
    content: $sh-smile-mouth-open;
  }
}
.sh-smile-neutral {
  &:before {
    content: $sh-smile-neutral;
  }
}
.sh-smile-no-mouth {
  &:before {
    content: $sh-smile-no-mouth;
  }
}
.sh-smile-none {
  &:before {
    content: $sh-smile-none;
  }
}
.sh-smile-sad {
  &:before {
    content: $sh-smile-sad;
  }
}
.sh-smile-upside {
  &:before {
    content: $sh-smile-upside;
  }
}
.sh-stack {
  &:before {
    content: $sh-stack;
  }
}
.sh-trash {
  &:before {
    content: $sh-trash;
  }
}
.sh-trash-empty {
  &:before {
    content: $sh-trash-empty;
  }
}
.sh-edit {
  &:before {
    content: $sh-edit;
  }
}
.sh-question {
  &:before {
    content: $sh-question;
  }
}
.sh-question-circle {
  &:before {
    content: $sh-question-circle;
  }
}
.sh-arrange-back {
  &:before {
    content: $sh-arrange-back;
  }
}
.sh-arrange-front {
  &:before {
    content: $sh-arrange-front;
  }
}
.sh-asterisk {
  &:before {
    content: $sh-asterisk;
  }
}
.sh-attribution {
  &:before {
    content: $sh-attribution;
  }
}
.sh-border-all {
  &:before {
    content: $sh-border-all;
  }
}
.sh-border-bottom .path1 {
  &:before {
    content: $sh-border-bottom-path1;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.sh-border-bottom .path2 {
  &:before {
    content: $sh-border-bottom-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-border-left .path1 {
  &:before {
    content: $sh-border-left-path1;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.sh-border-left .path2 {
  &:before {
    content: $sh-border-left-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-border-right .path1 {
  &:before {
    content: $sh-border-right-path1;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.sh-border-right .path2 {
  &:before {
    content: $sh-border-right-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-border-style-dashed {
  &:before {
    content: $sh-border-style-dashed;
  }
}
.sh-border-style-dotted {
  &:before {
    content: $sh-border-style-dotted;
  }
}
.sh-border-style-solid {
  &:before {
    content: $sh-border-style-solid;
  }
}
.sh-border-top .path1 {
  &:before {
    content: $sh-border-top-path1;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.sh-border-top .path2 {
  &:before {
    content: $sh-border-top-path2;
    margin-left: -1em;
    color: rgb(17, 0, 0);
  }
}
.sh-color-bucket {
  &:before {
    content: $sh-color-bucket;
  }
}
.sh-color-picker {
  &:before {
    content: $sh-color-picker;
  }
}
.sh-distribute-vertical {
  &:before {
    content: $sh-distribute-vertical;
    color: #000;
  }
}
.sh-drop {
  &:before {
    content: $sh-drop;
  }
}
.sh-drop-invert {
  &:before {
    content: $sh-drop-invert;
  }
}
.sh-drop-opacity {
  &:before {
    content: $sh-drop-opacity;
  }
}
.sh-edit-black-point {
  &:before {
    content: $sh-edit-black-point;
  }
}
.sh-edit-contrast {
  &:before {
    content: $sh-edit-contrast;
  }
}
.sh-edit-exposure {
  &:before {
    content: $sh-edit-exposure;
  }
}
.sh-edit-fade {
  &:before {
    content: $sh-edit-fade;
    color: #000;
  }
}
.sh-edit-flip-h .path1 {
  &:before {
    content: $sh-edit-flip-h-path1;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-edit-flip-h .path2 {
  &:before {
    content: $sh-edit-flip-h-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-edit-flip-h .path3 {
  &:before {
    content: $sh-edit-flip-h-path3;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-edit-flip-v .path1 {
  &:before {
    content: $sh-edit-flip-v-path1;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-edit-flip-v .path2 {
  &:before {
    content: $sh-edit-flip-v-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-edit-flip-v .path3 {
  &:before {
    content: $sh-edit-flip-v-path3;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-edit-highlight {
  &:before {
    content: $sh-edit-highlight;
  }
}
.sh-edit-markup {
  &:before {
    content: $sh-edit-markup;
  }
}
.sh-edit-mask {
  &:before {
    content: $sh-edit-mask;
  }
}
.sh-edit-noise {
  &:before {
    content: $sh-edit-noise;
  }
}
.sh-edit-shadows {
  &:before {
    content: $sh-edit-shadows;
  }
}
.sh-edit-straight {
  &:before {
    content: $sh-edit-straight;
  }
}
.sh-edit-unmask {
  &:before {
    content: $sh-edit-unmask;
  }
}
.sh-erase {
  &:before {
    content: $sh-erase;
  }
}
.sh-font-height {
  &:before {
    content: $sh-font-height;
  }
}
.sh-font-spacing {
  &:before {
    content: $sh-font-spacing;
  }
}
.sh-insert-after {
  &:before {
    content: $sh-insert-after;
  }
}
.sh-insert-after-o {
  &:before {
    content: $sh-insert-after-o;
  }
}
.sh-insert-after-r {
  &:before {
    content: $sh-insert-after-r;
  }
}
.sh-insert-before {
  &:before {
    content: $sh-insert-before;
  }
}
.sh-insert-before-o {
  &:before {
    content: $sh-insert-before-o;
  }
}
.sh-insert-before-r {
  &:before {
    content: $sh-insert-before-r;
  }
}
.sh-list-tree {
  &:before {
    content: $sh-list-tree;
  }
}
.sh-path-back {
  &:before {
    content: $sh-path-back;
  }
}
.sh-path-crop {
  &:before {
    content: $sh-path-crop;
  }
}
.sh-path-divide {
  &:before {
    content: $sh-path-divide;
  }
}
.sh-path-exclude {
  &:before {
    content: $sh-path-exclude;
  }
}
.sh-path-front {
  &:before {
    content: $sh-path-front;
  }
}
.sh-path-intersect {
  &:before {
    content: $sh-path-intersect;
  }
}
.sh-path-outline {
  &:before {
    content: $sh-path-outline;
  }
}
.sh-path-trim {
  &:before {
    content: $sh-path-trim;
  }
}
.sh-path-unite {
  &:before {
    content: $sh-path-unite;
  }
}
.sh-pen {
  &:before {
    content: $sh-pen;
  }
}
.sh-pentagon-bottom-left {
  &:before {
    content: $sh-pentagon-bottom-left;
  }
}
.sh-pentagon-bottom-right {
  &:before {
    content: $sh-pentagon-bottom-right;
  }
}
.sh-pentagon-down {
  &:before {
    content: $sh-pentagon-down;
  }
}
.sh-pentagon-left {
  &:before {
    content: $sh-pentagon-left;
  }
}
.sh-pentagon-right {
  &:before {
    content: $sh-pentagon-right;
  }
}
.sh-pentagon-top-left {
  &:before {
    content: $sh-pentagon-top-left;
  }
}
.sh-pentagon-top-right {
  &:before {
    content: $sh-pentagon-top-right;
  }
}
.sh-pentagon-up {
  &:before {
    content: $sh-pentagon-up;
  }
}
.sh-poll {
  &:before {
    content: $sh-poll;
  }
}
.sh-rename {
  &:before {
    content: $sh-rename;
  }
}
.sh-row-first .path1 {
  &:before {
    content: $sh-row-first-path1;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-row-first .path2 {
  &:before {
    content: $sh-row-first-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-row-first .path3 {
  &:before {
    content: $sh-row-first-path3;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-row-last .path1 {
  &:before {
    content: $sh-row-last-path1;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-row-last .path2 {
  &:before {
    content: $sh-row-last-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-row-last .path3 {
  &:before {
    content: $sh-row-last-path3;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-shape-circle {
  &:before {
    content: $sh-shape-circle;
  }
}
.sh-shape-half-circle {
  &:before {
    content: $sh-shape-half-circle;
  }
}
.sh-shape-hexagon {
  &:before {
    content: $sh-shape-hexagon;
  }
}
.sh-shape-rhombus {
  &:before {
    content: $sh-shape-rhombus;
  }
}
.sh-shape-square {
  &:before {
    content: $sh-shape-square;
  }
}
.sh-shape-triangle {
  &:before {
    content: $sh-shape-triangle;
  }
}
.sh-shape-zigzag {
  &:before {
    content: $sh-shape-zigzag;
  }
}
.sh-stories {
  &:before {
    content: $sh-stories;
  }
}
.sh-template {
  &:before {
    content: $sh-template;
  }
}
.sh-tikcode {
  &:before {
    content: $sh-tikcode;
  }
}
.sh-toolbar-bottom {
  &:before {
    content: $sh-toolbar-bottom;
  }
}
.sh-toolbar-left {
  &:before {
    content: $sh-toolbar-left;
  }
}
.sh-toolbar-right {
  &:before {
    content: $sh-toolbar-right;
  }
}
.sh-toolbar-top {
  &:before {
    content: $sh-toolbar-top;
  }
}
.sh-view-cols {
  &:before {
    content: $sh-view-cols;
  }
}
.sh-view-comfortable {
  &:before {
    content: $sh-view-comfortable;
  }
}
.sh-view-day {
  &:before {
    content: $sh-view-day;
  }
}
.sh-view-grid {
  &:before {
    content: $sh-view-grid;
  }
}
.sh-view-list {
  &:before {
    content: $sh-view-list;
  }
}
.sh-view-month {
  &:before {
    content: $sh-view-month;
  }
}
.sh-view-split {
  &:before {
    content: $sh-view-split;
  }
}
.sh-website {
  &:before {
    content: $sh-website;
  }
}
.sh-apple-watch {
  &:before {
    content: $sh-apple-watch;
  }
}
.sh-bot {
  &:before {
    content: $sh-bot;
  }
}
.sh-browser {
  &:before {
    content: $sh-browser;
  }
}
.sh-calculator {
  &:before {
    content: $sh-calculator;
  }
}
.sh-camera {
  &:before {
    content: $sh-camera;
  }
}
.sh-cast {
  &:before {
    content: $sh-cast;
  }
}
.sh-controller {
  &:before {
    content: $sh-controller;
  }
}
.sh-desktop {
  &:before {
    content: $sh-desktop;
  }
}
.sh-dialpad {
  &:before {
    content: $sh-dialpad;
  }
}
.sh-drive {
  &:before {
    content: $sh-drive;
  }
}
.sh-ereader {
  &:before {
    content: $sh-ereader;
  }
}
.sh-home-screen {
  &:before {
    content: $sh-home-screen;
  }
}
.sh-keyboard {
  &:before {
    content: $sh-keyboard;
  }
}
.sh-laptop {
  &:before {
    content: $sh-laptop;
  }
}
.sh-mouse {
  &:before {
    content: $sh-mouse;
  }
}
.sh-music-speaker {
  &:before {
    content: $sh-music-speaker;
  }
}
.sh-piano {
  &:before {
    content: $sh-piano;
  }
}
.sh-qr {
  &:before {
    content: $sh-qr;
  }
}
.sh-screen {
  &:before {
    content: $sh-screen;
  }
}
.sh-screen-wide {
  &:before {
    content: $sh-screen-wide;
  }
}
.sh-server {
  &:before {
    content: $sh-server;
  }
}
.sh-shield {
  &:before {
    content: $sh-shield;
  }
}
.sh-signal {
  &:before {
    content: $sh-signal;
  }
}
.sh-smartphone {
  &:before {
    content: $sh-smartphone;
  }
}
.sh-smartphone-chip {
  &:before {
    content: $sh-smartphone-chip;
  }
}
.sh-smartphone-ram {
  &:before {
    content: $sh-smartphone-ram;
  }
}
.sh-smartphone-shake {
  &:before {
    content: $sh-smartphone-shake;
  }
}
.sh-stopwatch {
  &:before {
    content: $sh-stopwatch;
  }
}
.sh-thermostat {
  &:before {
    content: $sh-thermostat;
  }
}
.sh-touchpad {
  &:before {
    content: $sh-touchpad;
  }
}
.sh-tv {
  &:before {
    content: $sh-tv;
  }
}
.sh-webcam {
  &:before {
    content: $sh-webcam;
  }
}
.sh-box {
  &:before {
    content: $sh-box;
  }
}
.sh-cloud {
  &:before {
    content: $sh-cloud;
  }
}
.sh-file {
  &:before {
    content: $sh-file;
  }
}
.sh-file-add {
  &:before {
    content: $sh-file-add;
  }
}
.sh-file-document {
  &:before {
    content: $sh-file-document;
  }
}
.sh-file-remove {
  &:before {
    content: $sh-file-remove;
  }
}
.sh-folder {
  &:before {
    content: $sh-folder;
  }
}
.sh-folder-add {
  &:before {
    content: $sh-folder-add;
  }
}
.sh-folder-remove {
  &:before {
    content: $sh-folder-remove;
  }
}
.sh-toolbox {
  &:before {
    content: $sh-toolbox;
  }
}
.sh-album {
  &:before {
    content: $sh-album;
  }
}
.sh-collage {
  &:before {
    content: $sh-collage;
  }
}
.sh-comment {
  &:before {
    content: $sh-comment;
  }
}
.sh-credit-card {
  &:before {
    content: $sh-credit-card;
  }
}
.sh-data {
  &:before {
    content: $sh-data;
  }
}
.sh-database {
  &:before {
    content: $sh-database;
  }
}
.sh-disc {
  &:before {
    content: $sh-disc;
  }
}
.sh-film {
  &:before {
    content: $sh-film;
  }
}
.sh-games {
  &:before {
    content: $sh-games;
  }
}
.sh-headset {
  &:before {
    content: $sh-headset;
  }
}
.sh-image {
  &:before {
    content: $sh-image;
  }
}
.sh-inpicture {
  &:before {
    content: $sh-inpicture;
  }
}
.sh-live-photo {
  &:before {
    content: $sh-live-photo;
  }
}
.sh-media-live {
  &:before {
    content: $sh-media-live;
  }
}
.sh-media-podcast {
  &:before {
    content: $sh-media-podcast;
  }
}
.sh-mic {
  &:before {
    content: $sh-mic;
  }
}
.sh-music {
  &:before {
    content: $sh-music;
  }
}
.sh-music-note {
  &:before {
    content: $sh-music-note;
  }
}
.sh-photoscan {
  &:before {
    content: $sh-photoscan;
  }
}
.sh-play-backwards {
  &:before {
    content: $sh-play-backwards;
  }
}
.sh-play-button {
  &:before {
    content: $sh-play-button;
  }
}
.sh-play-button-o {
  &:before {
    content: $sh-play-button-o;
  }
}
.sh-play-button-r {
  &:before {
    content: $sh-play-button-r;
  }
}
.sh-play-forwards {
  &:before {
    content: $sh-play-forwards;
  }
}
.sh-play-list {
  &:before {
    content: $sh-play-list;
  }
}
.sh-play-list-add {
  &:before {
    content: $sh-play-list-add;
  }
}
.sh-play-list-check {
  &:before {
    content: $sh-play-list-check;
  }
}
.sh-play-list-remove {
  &:before {
    content: $sh-play-list-remove;
  }
}
.sh-play-list-search {
  &:before {
    content: $sh-play-list-search;
  }
}
.sh-play-track-next {
  &:before {
    content: $sh-play-track-next;
  }
}
.sh-play-track-next-o {
  &:before {
    content: $sh-play-track-next-o;
  }
}
.sh-play-track-next-r {
  &:before {
    content: $sh-play-track-next-r;
  }
}
.sh-play-track-prev {
  &:before {
    content: $sh-play-track-prev;
  }
}
.sh-play-track-prev-o {
  &:before {
    content: $sh-play-track-prev-o;
  }
}
.sh-play-track-prev-r {
  &:before {
    content: $sh-play-track-prev-r;
  }
}
.sh-polaroid {
  &:before {
    content: $sh-polaroid;
  }
}
.sh-presentation {
  &:before {
    content: $sh-presentation;
  }
}
.sh-ratio {
  &:before {
    content: $sh-ratio;
  }
}
.sh-screen-mirror {
  &:before {
    content: $sh-screen-mirror;
  }
}
.sh-screen-shot {
  &:before {
    content: $sh-screen-shot;
  }
}
.sh-ui-kit {
  &:before {
    content: $sh-ui-kit;
  }
}
.sh-usb {
  &:before {
    content: $sh-usb;
  }
}
.sh-usb-c {
  &:before {
    content: $sh-usb-c;
  }
}
.sh-voicemail {
  &:before {
    content: $sh-voicemail;
  }
}
.sh-voicemail-o {
  &:before {
    content: $sh-voicemail-o;
  }
}
.sh-voicemail-r {
  &:before {
    content: $sh-voicemail-r;
  }
}
.sh-volume {
  &:before {
    content: $sh-volume;
  }
}
.sh-setting {
  &:before {
    content: $sh-setting;
  }
}
.sh-shared-user {
  &:before {
    content: $sh-shared-user;
  }
}
.sh-single-user {
  &:before {
    content: $sh-single-user;
  }
}
.sh-airplane {
  &:before {
    content: $sh-airplane;
  }
}
.sh-anchor {
  &:before {
    content: $sh-anchor;
  }
}
.sh-awards {
  &:before {
    content: $sh-awards;
  }
}
.sh-band-aid {
  &:before {
    content: $sh-band-aid;
  }
}
.sh-bee {
  &:before {
    content: $sh-bee;
  }
}
.sh-bitbucket {
  &:before {
    content: $sh-bitbucket;
  }
}
.sh-block {
  &:before {
    content: $sh-block;
  }
}
.sh-bowl {
  &:before {
    content: $sh-bowl;
  }
}
.sh-boy {
  &:before {
    content: $sh-boy;
  }
}
.sh-briefcase {
  &:before {
    content: $sh-briefcase;
  }
}
.sh-browse {
  &:before {
    content: $sh-browse;
  }
}
.sh-brush {
  &:before {
    content: $sh-brush;
  }
}
.sh-calibrate {
  &:before {
    content: $sh-calibrate;
  }
}
.sh-cap {
  &:before {
    content: $sh-cap;
  }
}
.sh-card-clubs {
  &:before {
    content: $sh-card-clubs;
  }
}
.sh-card-diamonds {
  &:before {
    content: $sh-card-diamonds;
  }
}
.sh-card-hearts {
  &:before {
    content: $sh-card-hearts;
  }
}
.sh-card-spades {
  &:before {
    content: $sh-card-spades;
  }
}
.sh-carousel {
  &:before {
    content: $sh-carousel;
  }
}
.sh-chart {
  &:before {
    content: $sh-chart;
  }
}
.sh-clapper-board {
  &:before {
    content: $sh-clapper-board;
  }
}
.sh-close {
  &:before {
    content: $sh-close;
  }
}
.sh-coffee {
  &:before {
    content: $sh-coffee;
  }
}
.sh-community {
  &:before {
    content: $sh-community;
  }
}
.sh-components {
  &:before {
    content: $sh-components;
  }
}
.sh-copyright {
  &:before {
    content: $sh-copyright;
  }
}
.sh-cross {
  &:before {
    content: $sh-cross;
  }
}
.sh-crown {
  &:before {
    content: $sh-crown;
  }
}
.sh-dark-mode {
  &:before {
    content: $sh-dark-mode;
  }
}
.sh-debug {
  &:before {
    content: $sh-debug;
  }
}
.sh-dice-1 {
  &:before {
    content: $sh-dice-1;
  }
}
.sh-dice-2 {
  &:before {
    content: $sh-dice-2;
  }
}
.sh-dice-3 {
  &:before {
    content: $sh-dice-3;
  }
}
.sh-dice-4 {
  &:before {
    content: $sh-dice-4;
  }
}
.sh-dice-5 {
  &:before {
    content: $sh-dice-5;
  }
}
.sh-dice-6 {
  &:before {
    content: $sh-dice-6;
  }
}
.sh-dock-bottom {
  &:before {
    content: $sh-dock-bottom;
  }
}
.sh-dock-left {
  &:before {
    content: $sh-dock-left;
  }
}
.sh-dock-right {
  &:before {
    content: $sh-dock-right;
  }
}
.sh-dock-window {
  &:before {
    content: $sh-dock-window;
  }
}
.sh-dollar {
  &:before {
    content: $sh-dollar;
  }
}
.sh-eject {
  &:before {
    content: $sh-eject;
  }
}
.sh-ericsson {
  &:before {
    content: $sh-ericsson;
  }
}
.sh-ethernet {
  &:before {
    content: $sh-ethernet;
  }
}
.sh-euro {
  &:before {
    content: $sh-euro;
  }
}
.sh-extension {
  &:before {
    content: $sh-extension;
  }
}
.sh-extension-add {
  &:before {
    content: $sh-extension-add;
  }
}
.sh-extension-alt {
  &:before {
    content: $sh-extension-alt;
  }
}
.sh-extension-remove {
  &:before {
    content: $sh-extension-remove;
  }
}
.sh-external {
  &:before {
    content: $sh-external;
  }
}
.sh-eye {
  &:before {
    content: $sh-eye;
  }
}
.sh-eye-alt {
  &:before {
    content: $sh-eye-alt;
  }
}
.sh-filters {
  &:before {
    content: $sh-filters;
  }
}
.sh-flag-alt {
  &:before {
    content: $sh-flag-alt;
  }
}
.sh-gender-female {
  &:before {
    content: $sh-gender-female;
  }
}
.sh-gender-male {
  &:before {
    content: $sh-gender-male;
  }
}
.sh-ghost-character {
  &:before {
    content: $sh-ghost-character;
  }
}
.sh-gift {
  &:before {
    content: $sh-gift;
  }
}
.sh-girl {
  &:before {
    content: $sh-girl;
  }
}
.sh-glass {
  &:before {
    content: $sh-glass;
  }
}
.sh-glass-alt {
  &:before {
    content: $sh-glass-alt;
  }
}
.sh-globe {
  &:before {
    content: $sh-globe;
  }
}
.sh-globe-alt {
  &:before {
    content: $sh-globe-alt;
  }
}
.sh-gym {
  &:before {
    content: $sh-gym;
  }
}
.sh-heart {
  &:before {
    content: $sh-heart;
  }
}
.sh-home {
  &:before {
    content: $sh-home;
  }
}
.sh-home-alt {
  &:before {
    content: $sh-home-alt;
  }
}
.sh-icecream {
  &:before {
    content: $sh-icecream;
  }
}
.sh-infinity {
  &:before {
    content: $sh-infinity;
  }
}
.sh-insights {
  &:before {
    content: $sh-insights;
  }
}
.sh-key {
  &:before {
    content: $sh-key;
  }
}
.sh-keyhole {
  &:before {
    content: $sh-keyhole;
  }
}
.sh-loadbar {
  &:before {
    content: $sh-loadbar;
  }
}
.sh-loadbar-alt .path1 {
  &:before {
    content: $sh-loadbar-alt-path1;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.sh-loadbar-alt .path2 {
  &:before {
    content: $sh-loadbar-alt-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-loadbar-doc {
  &:before {
    content: $sh-loadbar-doc;
  }
}
.sh-loadbar-sound {
  &:before {
    content: $sh-loadbar-sound;
  }
}
.sh-loupe {
  &:before {
    content: $sh-loupe;
  }
}
.sh-magnet .path1 {
  &:before {
    content: $sh-magnet-path1;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-magnet .path2 {
  &:before {
    content: $sh-magnet-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-magnet .path3 {
  &:before {
    content: $sh-magnet-path3;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-mail {
  &:before {
    content: $sh-mail;
  }
}
.sh-mail-forward {
  &:before {
    content: $sh-mail-forward;
  }
}
.sh-mail-open {
  &:before {
    content: $sh-mail-open;
  }
}
.sh-mail-reply {
  &:before {
    content: $sh-mail-reply;
  }
}
.sh-maximize {
  &:before {
    content: $sh-maximize;
  }
}
.sh-maximize-alt {
  &:before {
    content: $sh-maximize-alt;
  }
}
.sh-menu-cake {
  &:before {
    content: $sh-menu-cake;
  }
}
.sh-menu-cheese {
  &:before {
    content: $sh-menu-cheese;
  }
}
.sh-menu-grid-o {
  &:before {
    content: $sh-menu-grid-o;
  }
}
.sh-menu-grid-r {
  &:before {
    content: $sh-menu-grid-r;
  }
}
.sh-menu-hotdog {
  &:before {
    content: $sh-menu-hotdog;
  }
}
.sh-menu-motion {
  &:before {
    content: $sh-menu-motion;
  }
}
.sh-menu-oreos {
  &:before {
    content: $sh-menu-oreos;
  }
}
.sh-mini-player {
  &:before {
    content: $sh-mini-player;
  }
}
.sh-modem {
  &:before {
    content: $sh-modem;
  }
}
.sh-moon {
  &:before {
    content: $sh-moon;
  }
}
.sh-nametag {
  &:before {
    content: $sh-nametag;
  }
}
.sh-notifications {
  &:before {
    content: $sh-notifications;
  }
}
.sh-open-collective .path1 {
  &:before {
    content: $sh-open-collective-path1;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-open-collective .path2 {
  &:before {
    content: $sh-open-collective-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-options {
  &:before {
    content: $sh-options;
  }
}
.sh-organisation {
  &:before {
    content: $sh-organisation;
  }
}
.sh-password {
  &:before {
    content: $sh-password;
  }
}
.sh-performance {
  &:before {
    content: $sh-performance;
  }
}
.sh-pill {
  &:before {
    content: $sh-pill;
  }
}
.sh-plug {
  &:before {
    content: $sh-plug;
  }
}
.sh-profile {
  &:before {
    content: $sh-profile;
  }
}
.sh-pull-clear {
  &:before {
    content: $sh-pull-clear;
  }
}
.sh-radio-check {
  &:before {
    content: $sh-radio-check;
  }
}
.sh-radio-checked {
  &:before {
    content: $sh-radio-checked;
  }
}
.sh-readme {
  &:before {
    content: $sh-readme;
  }
}
.sh-record {
  &:before {
    content: $sh-record;
  }
}
.sh-remove {
  &:before {
    content: $sh-remove;
  }
}
.sh-remove-r {
  &:before {
    content: $sh-remove-r;
  }
}
.sh-reorder .path1 {
  &:before {
    content: $sh-reorder-path1;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-reorder .path2 {
  &:before {
    content: $sh-reorder-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-reorder .path3 {
  &:before {
    content: $sh-reorder-path3;
    margin-left: -1em;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-reorder .path4 {
  &:before {
    content: $sh-reorder-path4;
    margin-left: -1em;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-reorder .path5 {
  &:before {
    content: $sh-reorder-path5;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-ring {
  &:before {
    content: $sh-ring;
  }
}
.sh-sand-clock {
  &:before {
    content: $sh-sand-clock;
  }
}
.sh-scan {
  &:before {
    content: $sh-scan;
  }
}
.sh-search {
  &:before {
    content: $sh-search;
  }
}
.sh-search-found {
  &:before {
    content: $sh-search-found;
  }
}
.sh-search-loading {
  &:before {
    content: $sh-search-loading;
  }
}
.sh-shopping-bag {
  &:before {
    content: $sh-shopping-bag;
  }
}
.sh-shopping-cart {
  &:before {
    content: $sh-shopping-cart;
  }
}
.sh-sidebar {
  &:before {
    content: $sh-sidebar;
  }
}
.sh-sidebar-open {
  &:before {
    content: $sh-sidebar-open;
  }
}
.sh-sidebar-right {
  &:before {
    content: $sh-sidebar-right;
  }
}
.sh-sleep {
  &:before {
    content: $sh-sleep;
  }
}
.sh-smart-home-boiler {
  &:before {
    content: $sh-smart-home-boiler;
  }
}
.sh-smart-home-cooker {
  &:before {
    content: $sh-smart-home-cooker;
  }
}
.sh-smart-home-heat {
  &:before {
    content: $sh-smart-home-heat;
  }
}
.sh-smart-home-light {
  &:before {
    content: $sh-smart-home-light;
  }
}
.sh-smart-home-refrigerator {
  &:before {
    content: $sh-smart-home-refrigerator;
  }
}
.sh-smart-home-wash-machine {
  &:before {
    content: $sh-smart-home-wash-machine;
  }
}
.sh-space-between {
  &:before {
    content: $sh-space-between;
  }
}
.sh-space-between-v {
  &:before {
    content: $sh-space-between-v;
  }
}
.sh-spinner .path1 {
  &:before {
    content: $sh-spinner-path1;
    color: rgb(0, 0, 0);
    opacity: 0.2;
  }
}
.sh-spinner .path2 {
  &:before {
    content: $sh-spinner-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-spinner-alt {
  &:before {
    content: $sh-spinner-alt;
  }
}
.sh-spinner-two .path1 {
  &:before {
    content: $sh-spinner-two-path1;
    color: rgb(0, 0, 0);
    opacity: 0.2;
  }
}
.sh-spinner-two .path2 {
  &:before {
    content: $sh-spinner-two-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-spinner-two .path3 {
  &:before {
    content: $sh-spinner-two-path3;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-spinner-two-alt {
  &:before {
    content: $sh-spinner-two-alt;
  }
}
.sh-style {
  &:before {
    content: $sh-style;
  }
}
.sh-sun {
  &:before {
    content: $sh-sun;
  }
}
.sh-support {
  &:before {
    content: $sh-support;
  }
}
.sh-tab {
  &:before {
    content: $sh-tab;
  }
}
.sh-tag {
  &:before {
    content: $sh-tag;
  }
}
.sh-tap-double {
  &:before {
    content: $sh-tap-double;
  }
}
.sh-tap-single {
  &:before {
    content: $sh-tap-single;
  }
}
.sh-tennis {
  &:before {
    content: $sh-tennis;
  }
}
.sh-terrain {
  &:before {
    content: $sh-terrain;
  }
}
.sh-thermometer {
  &:before {
    content: $sh-thermometer;
  }
}
.sh-time {
  &:before {
    content: $sh-time;
  }
}
.sh-timelapse {
  &:before {
    content: $sh-timelapse;
  }
}
.sh-timer {
  &:before {
    content: $sh-timer;
  }
}
.sh-today .path1 {
  &:before {
    content: $sh-today-path1;
    color: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
.sh-today .path2 {
  &:before {
    content: $sh-today-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-today .path3 {
  &:before {
    content: $sh-today-path3;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.sh-track {
  &:before {
    content: $sh-track;
  }
}
.sh-transcript {
  &:before {
    content: $sh-transcript;
  }
}
.sh-tree {
  &:before {
    content: $sh-tree;
  }
}
.sh-trees {
  &:before {
    content: $sh-trees;
  }
}
.sh-trophy {
  &:before {
    content: $sh-trophy;
  }
}
.sh-umbrella {
  &:before {
    content: $sh-umbrella;
  }
}
.sh-unavailable {
  &:before {
    content: $sh-unavailable;
  }
}
.sh-unblock {
  &:before {
    content: $sh-unblock;
  }
}
.sh-user {
  &:before {
    content: $sh-user;
  }
}
.sh-user-add {
  &:before {
    content: $sh-user-add;
  }
}
.sh-user-list {
  &:before {
    content: $sh-user-list;
  }
}
.sh-user-remove {
  &:before {
    content: $sh-user-remove;
  }
}
.sh-vinyl {
  &:before {
    content: $sh-vinyl;
  }
}
.sh-work-alt {
  &:before {
    content: $sh-work-alt;
  }
}
.sh-yinyang {
  &:before {
    content: $sh-yinyang;
  }
}
.sh-zoom-in {
  &:before {
    content: $sh-zoom-in;
  }
}
.sh-zoom-out {
  &:before {
    content: $sh-zoom-out;
  }
}
