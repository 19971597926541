.subscription-plan {
  &__container {
    .card {
      box-shadow: 0px 2px 4px rgba(234, 237, 239, 0.65);

      .card-body {
        padding: 0px;

        .progress-bar-container {
          width: 47.6133%;
        }
      }
    }
    .accordion__panel {
      padding-top: 0.75rem !important;
    }
  }
  &__info {
    display: flex;
    justify-content: space-between;

    .canceled-plan {
      color: $red-12;
    }

    .paused-plan {
      color: $orange-12;
    }
  }

  &__expire-info {
    width: 14.9375rem;
    background: $red-14;
    border-radius: 0.25rem;
    padding: 0.125rem 0px;
    text-align: center;
  }

  &__number-input {
    .slot-input {
      height: 2.5rem;
      .bs-input-number-input-wrap {
        height: inherit;
        input {
          height: inherit;
        }
      }
    }
    .number-input-waring-text {
      color: $red-13;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 15px;
      margin-top: 0.5rem;
    }
  }

  &__bill-overview-wrapper {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-bottom: 1rem;
  }

  &__divider {
    width: 100%;
    height: 0.125rem;
    background: $gray-13;
  }

  &__loader-container {
    height: 8.0625rem;
    img {
      height: 2.5rem;
    }
  }

  .progress-bar-wrapper {
    height: 0.625rem;
    &__bar {
      @keyframes progressBar {
        0% {
          width: 0;
        }
        100% {
          width: 100%;
        }
      }
    }
  }
}
