.auth-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.25rem;

  width: 100%;

  .auth-form-row {
    display: flex;
    justify-content: center;
    gap: 1.25rem;
    line-height: normal;

    .auth-form-input {
      width: 13.75rem;
      line-height: normal;

      &.email {
        width: 28.75rem;
      }

      &.password {
        width: 28.75rem;
        .password-validation-label {
          font-size: 0.625rem;
          line-height: 1.125rem;
        }
        .password-validation-checklist-container {
          margin-bottom: 0;

          ul li p {
            font-weight: $font-weight-medium;
            font-size: 0.75rem;
            line-height: 1.125rem;
          }
        }
      }

      .bs-input-container {
        margin: 0;
        line-height: normal;

        .bs-input-label-prefix {
          font-weight: $font-weight-semibold;
          font-size: 0.75rem;
          line-height: 1.125rem;
          color: $gray-15;
          margin-bottom: 0.3125rem;
        }

        .bs-input-affix-wrapper {
          height: 2.25rem;
          padding: 0.5rem 1rem;
          background: $gray-1;
          border: 0.0625rem solid $gray-13;
          border-radius: 0.25rem;
          line-height: 1.25rem;
          box-sizing: border-box;
          transition: all 0.2s ease-in-out;

          .bs-input {
            font-weight: $font-weight-medium;
            font-size: 0.875rem;
            line-height: 1.25rem;
            color: $blue-15;

            &::placeholder {
              color: $gray-23;
            }
          }

          &:focus,
          &:focus-within {
            outline: none;
            box-shadow: none;
            border-color: $blue-11;
          }

          &.bs-input-affix-wrapper-disabled {
            background: #f5f5f5;
          }

          &.input-error {
            border-color: #fca5a5;

            &:focus,
            &:focus-within {
              border-color: #fca5a5;
            }
          }
        }

        .bs-input-label-suffix {
          width: 100%;
          height: 1.125rem;

          font-weight: $font-weight-normal;
          font-size: 0.75rem;
          line-height: 1.125rem;
          color: $red-12;

          mix-blend-mode: normal;

          margin-top: 0.25rem;
        }
      }

      &.phone {
        p {
          font-weight: $font-weight-semibold;
          font-size: 0.75rem;
          line-height: 1.125rem;
          color: $gray-15;
          margin: 0;
          margin-bottom: 0.3125rem;
          margin-top: 0.25rem;
        }
        .phone-form-input {
          display: flex;
          align-items: center;

          .form-control {
            width: 100%;
            height: 2.25rem;
            padding-left: 0.75rem;
            background: $gray-1;
            border: 0.0625rem solid $gray-13;
            border-radius: 0 0.25rem 0.25rem 0;
            line-height: 1.25rem;
            box-sizing: border-box;
            transition: all 0.2s ease-in-out;

            font-weight: $font-weight-medium;
            font-size: 0.875rem;
            color: $blue-15;

            &::placeholder {
              color: $gray-23;
            }

            &:focus,
            &:focus-within {
              outline: none;
              box-shadow: none;
              border-color: $blue-11;
            }
          }
          .flag-dropdown {
            border: 0.0625rem solid $gray-13;
            border-right: none;
            border-radius: 0.25rem 0 0 0.25rem;
            background-color: transparent;
            padding: 0.5rem;
            width: 3rem;
            height: 2.25rem;
            box-sizing: border-box;
            transition: all 0.2s ease-in-out;

            z-index: 1;
            position: relative;

            &:focus,
            &:focus-within {
              outline: none;
              box-shadow: none;
              border-color: $blue-11;

              & + .form-control {
                border-left-color: $blue-11;
              }
            }

            .selected-flag {
              width: 2rem;
              padding: 0;
              border-radius: 0;
              .flag {
                .arrow {
                  left: 1.625rem;
                }
              }
            }
          }

          &.input-error {
            .form-control,
            .flag-dropdown {
              border-color: #fca5a5;

              &:focus,
              &:focus-within {
                outline: none;
                box-shadow: none;
                border-color: #fca5a5;

                & + .form-control {
                  border-color: #fca5a5;
                }
              }
            }
          }
        }
        .phone-form-input-error {
          width: 13.75rem;
          height: 1.125rem;
          font-weight: $font-weight-normal;
          font-size: 0.75rem;
          line-height: 1.125rem;
          color: $red-12;
          mix-blend-mode: normal;
          margin: 0;
          margin-top: 0.25rem;
        }
      }

      .forgot-password {
        text-align: right;
        margin-top: 0.25rem;
        a {
          font-weight: $font-weight-semibold;
          font-size: 0.75rem;
          line-height: 1.125rem;
          color: $blue-11;
        }
      }
    }
  }
}

.privacy-policy-text {
  width: 100%;
  height: 2.75rem;
  margin-top: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;

  p {
    width: 21.25rem;
    font-weight: $font-weight-medium;
    font-size: 0.75rem;
    line-height: 1.125rem;
    text-align: center;
    color: $gray-15;
    margin: 0;

    a {
      color: $gray-15;
      text-decoration: underline;
      transition: all 0.2s ease;
      &:hover,
      &:focus {
        color: $blue-11;
      }
    }
  }
}

.bottom-navigation {
  width: 100%;
  height: 1.75rem;
  margin-top: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;

  p {
    width: 13.9375rem;
    font-weight: $font-weight-medium;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $gray-15;
    margin: 0;
    text-align: center;

    a {
      color: $blue-11;
      text-decoration: none;
      transition: all 0.2s ease;
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}
