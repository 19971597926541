.center-container {
  height: 70vh;
  &--content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &--vertical {
    display: flex;
    flex-direction: column;
    text-align: center;
    span {
      max-width: 20rem;
    }
  }
}
