.purchase-credits-modal {
  .purchase-credits-modal-header {
    padding: 0 1.2rem;
    margin-top: 0.3rem;
  }
  .modal-content {
    width: 28.125rem;

    .credits-plan-wrap {
      padding: 0.5rem 1rem;
      .credit-plan {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.6875rem 0.9375rem;
        margin-bottom: 0.9375rem;

        .best-value-txt {
          font-size: 0.625rem;
          font-weight: $font-weight-semibold;
          margin-left: 1.25rem;
          color: $blue-11;
        }
      }
      .disable-credit-plan {
        opacity: 0.5;
        pointer-events: none;
      }
      .border-gray {
        border: 1px solid $blue-14;
        border-radius: 0.225rem;
      }
      .border-blue {
        border: 1px solid $blue-11;
        border-radius: 0.125rem;
      }

      .purchase-ev-credits-agreement {
        font-size: 0.75rem;
        font-weight: $font-weight-normal;
        color: $gray-12;
        display: flex;
        align-items: flex-start;
        margin-bottom: 1rem;
        .bs-checkbox {
          padding-top: 0.325rem;
          margin-right: 0.125rem;
        }
      }
    }
  }
  .modal-body {
    .close-btn-wrap {
      position: absolute;
      top: 0.8rem;
      right: 0.8rem;
    }
  }
  .purchase-credits-modal-footer {
    padding: 0 2rem;
    height: 4rem;
    display: flex;
    align-items: center;
  }
  .payment-failed {
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    height: 17rem;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .modal-footer {
    padding: 0;
    width: 100%;
    height: 100%;
    div {
      .buy-now-btn.btn-solid-primary:disabled {
        cursor: not-allowed;
        background-color: rgba(29, 78, 216, 0.35);
        opacity: 1;
        color: $white;
      }
    }
  }
}
