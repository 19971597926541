.header-container {
  position: relative;
}

.header {
  position: absolute;
  top: 1.21875rem;
  z-index: 10;
  &__left-side {
    left: 1.875rem;
  }
  &__right-side {
    right: 1.875rem;
    gap: 1rem;
    .user-wrapper {
      z-index: 10;
      padding-left: 1.25rem;
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;

      &.separator::before {
        content: "";
        position: absolute;
        left: 0;
        width: 0.125rem;
        height: 1.875rem;
        border-radius: 0.0625rem;
        background: #e5e7eb;
      }
    }
    .current-user {
      cursor: pointer !important;
      position: relative;
    }
    .agency-logo {
      height: 1.875rem;
      min-width: 5.395625rem;
    }
    .trial-plan-banner {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.125rem;

      padding: 0.3125rem 1rem;
      height: 1.875rem;

      border-radius: 1.25rem;
      border: 0.0625rem solid #fef3c7;
      background: #fffbeb;

      img {
        margin-right: 0.1875rem;
      }

      p,
      button {
        margin: 0;
        font-size: 0.75rem;
        line-height: 1.25rem;
        font-weight: 500;
        color: #1f2937;
        span {
          font-weight: 600;
        }
      }

      button {
        background: transparent;
        border: none;
        outline: none;
        padding: 0;
        text-decoration: underline;
        text-underline-offset: 2.5px;
        cursor: pointer;

        &:hover,
        &:focus,
        &:focus-within {
          cursor: pointer;
          border: none;
          outline: none;
        }
      }
    }
  }
}
.logout-wrapper {
  top: 3.4375rem;
  right: 1.875rem;
  z-index: 10;
  min-width: 11.3125rem;
  background: $white;
  border: 1px solid $gray-13;
  border-radius: 0.25rem;
  box-shadow: 2px 2px 16px -3px rgba(4, 21, 76, 0.16),
    8px 16px 56px -24px rgba(156, 157, 161, 0.24);
  .logout-btn-container {
    border-top: 2px solid $gray-16;
    padding: 0.5rem 1.2rem;
    cursor: pointer;
    .logout-txt {
      color: #6b7280;
      margin-left: 0.5rem;
    }
  }
}
