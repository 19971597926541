.teams {
  padding: 0 1.875rem;
  .invite-member-btn {
    width: 9.3125rem;
  }
  .owner-tag {
    background: $yellow-11;
    border: 1px solid $orange-15;
    border-radius: 0.25rem;
    padding: 0.3125rem;
    color: $orange-15;
  }
  .admin-tag {
    background: $green-1;
    border: 1px solid $green-3;
    border-radius: 0.25rem;
    padding: 0.3125rem;
    color: $green-6;
  }
}

.invite-team-member-modal {
  .modal-content {
    width: 28.125rem;
  }
  .modal-header {
    padding: 0;
    padding-left: 1.675rem;
    padding-top: 1.875rem;
    position: relative;
    .close {
      position: absolute;
      right: 0.9375rem;
      top: 0.9375rem;
    }
  }
  .modal-body {
    .name-input {
      width: 11.4375rem !important;
    }
    .input-with-label {
      .input-default {
        height: 2.25rem;
      }
    }
  }
}

.assign-client-modal {
  .modal-dialog {
    max-width: 45rem;
  }

  .modal-header {
    .header-txt {
      width: 90%;
      color: $popover-arrow-color;
      font-size: 1rem;
      font-family: $font-weight-medium;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        font-weight: $font-weight-bolder;
      }
    }
    .modal-title {
      width: 83%;
      padding-left: 1rem;
      .admin-access-switch {
        height: 1rem;
        label {
          span {
            width: 6.0625rem;
            font-size: 0.875rem;
            font-weight: 600;
            color: $gray-15;
          }
        }
      }
    }
  }
  .modal-body {
    .table-list {
      max-height: calc(100vh - 15.75rem) !important;
    }
    .client-table-header.table-organism-header {
      background: $gray-16 !important;
      th {
        span {
          color: $gray-12;
        }
      }
    }
    .client-body-wrapper {
      border: 1px solid $gray-4 !important;
    }
    .client-body-wrapper > tr {
      padding-left: 2rem !important;
      .client-status {
        padding-left: 2rem;
        .switch {
          height: 1rem;
        }
      }
      .select-role {
        width: 10.9375rem;
      }
      &:not(:last-child) {
        & > td {
          border-bottom: none !important;
        }
      }
      & > td {
        &:first-child {
          border-left: 1px solid $gray-4 !important;
        }
        &:last-child {
          border-right: 1px solid $gray-4 !important;
        }
      }
    }
    .no-data {
      margin-top: 4rem;
      .empty-list__inner-vertical {
        flex-direction: column;
        left: 0%;
        transform: none;
      }
      .empty-list__content-vertical {
        height: 5rem;
      }
    }
  }
  .modal-footer {
    padding-left: 1.2rem;
    padding-right: 1.3rem;
    .btn-primary-disable {
      height: 2rem;
    }
  }
}
