.auth {
  &__container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $gray-26;
    .card {
      width: 25rem;
      position: relative;
      box-shadow: 0px 0px 10px $gray-13;
      .logo--section {
        position: absolute;
        top: -8.5%;
        left: 44%;
        width: 3.625rem;
        height: 3.625rem;
        background: $gray-26;
        border-bottom: 1px solid $gray-13;
        border-radius: 0 0 3.625rem 3.625rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .card-body {
        .forgot-link {
          display: flex;
          justify-content: flex-end;
        }
        .input-field {
          label {
            font-size: 0.75rem;
          }
          .bs-input-affix-wrapper {
            height: 2.25rem;
          }
          .bs-input-suffix {
            color: $gray-11;
          }
        }
        .btn-primary-disable {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }
        &__title {
          text-align: center;
          margin-bottom: 2.5rem;
          margin-top: 1.75rem;
          color: $popover-arrow-color;
        }
        &__btn {
          margin-top: 1.875rem;
        }
        &__btn-login {
          margin-top: 1.875rem;
        }
        .password-set-success {
          &__container {
            text-align: center;
            .sub-text {
              color: $gray-15;
              font-weight: $font-weight-medium;
              margin-top: 1.25rem;
              margin-bottom: 2.5rem;
            }
          }
        }
        .privacy-policy-txt {
          span {
            color: $gray-15;
            a {
              color: $blue-11;
              text-decoration: underline;
              margin-left: 0.15rem;
              margin-right: 0.15rem;
            }
          }
        }
      }
    }
    &.signup {
      overflow-y: auto;
      scroll-behavior: smooth;
      display: block;
      padding: 5.75rem 0 6.25rem 0;

      .signup-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .card {
        width: 36.25rem;
        padding: 1.5rem 3.75rem 3.25rem 3.75rem;
        .card-body {
          padding: 0;
          &__title {
            margin-bottom: 1.1875rem;
          }
        }
      }
    }
  }
}

@import "./components/signup-form/signup-form";
