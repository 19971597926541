.clients {
  .empty-content {
    .empty-list__content-vertical {
      p {
        margin-top: 0.5rem;
        width: 45.1875rem;
        max-width: 45.1875rem;
        text-align: center;
      }
    }
  }
  &__content {
    padding: 0 1.875rem;
  }
  &__list-container {
    .client-table-header.table-organism-header {
      z-index: 2 !important;
      background: $gray-16 !important;
      th {
        padding-left: 2rem !important;
        span {
          color: $gray-12;
        }
      }
    }

    .client-body-wrapper > tr {
      border: 1px solid $gray-4 !important;
      padding-left: 2rem !important;

      .client-status {
        padding-left: 2rem;
        .switch {
          height: 1rem;
        }
      }
      .client-name {
        &:hover {
          .active-client {
            color: $blue-11;
          }
        }
        .disable-client {
          color: $gray-27;
        }
        .login-icon {
          cursor: pointer;
        }
        .disable-action {
          cursor: not-allowed;
          pointer-events: none;
          opacity: 0.7;
        }
        .prevent-event {
          // pointer-events: none;
        }
      }
      .active-sequence {
        .disable-client {
          color: $gray-27;
        }
      }
      .active-email-account {
        .disable-client {
          color: $gray-27;
        }
      }
      .client-managed-by {
        .avatar-group-row {
          .avatar-tag {
            position: relative;
            background: $blue-13;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            span {
              color: $blue-11;
              font-size: 0.625rem;
            }
            &:hover {
              cursor: pointer;
              .custom-overlay-popover {
                display: flex !important;
              }
            }
          }
          .custom-overlay-popover {
            display: none !important;
            position: absolute;
            top: 2rem;
            background: $popover-arrow-color;
            min-width: 5.9375rem;
            width: max-content;
            max-height: 7.5rem;

            border-radius: 0.25rem;
            z-index: 1;
            span {
              color: $white;
            }
            &:before {
              content: "";
              position: absolute;
              top: -1rem;
              left: 45%;
              z-index: 1;
              border: solid 10px transparent;
              border-bottom-color: $blue-15;
            }
            &--inner {
              display: flex;
              flex-direction: column;
              padding: 0.625rem;

              width: 100%;
              max-height: 7.4375rem;
              overflow-y: auto;

              span {
                margin-bottom: 0.375rem;
              }

              &::-webkit-scrollbar {
                width: 0.25rem;
              }

              &::-webkit-scrollbar-thumb {
                border-radius: 0.25rem;
                background-color: $gray-15;
              }
            }
          }
        }
      }
      .action {
        .action-icon {
          cursor: pointer;
        }
        .disable-action {
          cursor: not-allowed;
          pointer-events: none;
          opacity: 0.7;
        }
      }

      &:hover {
        background: $gray-19 !important;

        .created-by-wrap {
          .sequence-created-by {
            display: none;
          }
          .sequence-actions {
            div {
              display: block;
            }
          }
        }
      }

      & > td {
        padding: 1.2rem 2rem !important;

        &:first-child {
          padding-left: 2rem !important;
          border-left: 1px solid $gray-4 !important;
        }
        &:last-child {
          border-right: 1px solid $gray-4 !important;
        }
      }
    }
  }
}

.add-client-modal {
  .modal-header {
    padding: 0;
    padding-left: 1.575rem;
    padding-top: 1.875rem;
    position: relative;
    .close {
      position: absolute;
      right: 0.9375rem;
      top: 0.9375rem;
    }
  }
  .modal-body {
    .input-field {
      .input-default {
        height: 2.25rem;
      }
    }
  }
  .modal-footer {
    .btn-primary-disabled.btn-solid {
      width: 7rem;
      padding: 0.25rem 1.3125rem !important;
    }
  }
}

.delete-modal {
  .modal-footer {
    .submit-btn.btn-solid {
      background: $red-12;
      border-width: 0;
    }
  }
}

.disable-client-confirmation-modal {
  .modal-footer {
    .btn-solid,
    .btn-ghost {
      width: 6.25rem !important;
    }
  }
}
