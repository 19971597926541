.onboarding-modal {
  &--wrapper {
    overflow-y: hidden !important;
    .modal-dialog {
      width: 52.875rem !important;
      min-width: 52.875rem !important;
      max-width: 52.875rem !important;

      .modal-content {
        width: 100%;
        height: 35.375rem !important;

        border-radius: 0.5rem;
        background: linear-gradient(180deg, $blue-13 0%, $gray-1 18.82%);

        .modal-content-container {
          height: 100%;
        }
        .modal-body {
          max-height: 100%;
          height: 35.375rem;
          overflow-y: auto;
          padding: 2.5rem 3.75rem 3.75rem 3.75rem;

          display: flex;
          justify-content: space-between;
          flex-direction: column;
          gap: 3rem;
        }
      }
    }
  }

  &--header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 2.25rem;

    &.isLoading {
      margin-bottom: 1.88rem;
    }

    h1 {
      font-weight: $font-weight-medium;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $blue-15;
      margin: 0;

      span {
        font-weight: $font-weight-bold;
      }
    }
    p {
      font-weight: $font-weight-medium;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: $gray-15;
      margin: 0;
    }
  }

  &--question-wrapper {
    height: 17.875rem;
  }

  &--question-number {
    font-weight: $font-weight-medium;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: $gray-15;
    margin: 0;
    margin-bottom: 0.75rem;
  }

  &--question {
    font-weight: $font-weight-semibold;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $blue-15;
    margin: 0;
    margin-bottom: 1.5rem;
  }

  &--options {
    display: flex;
    gap: 1.25rem;
    flex-wrap: wrap;
  }

  &--option {
    width: 10.25rem;
    height: 5.875rem;
    padding: 1.25rem 0.75rem;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 0.25rem;
    border: 0.0625rem solid $gray-13;
    box-sizing: border-box;
    background: $gray-1;

    position: relative;

    transition: all 0.2s ease-in-out;

    span {
      font-weight: $font-weight-medium;
      font-size: 0.75rem;
      line-height: 1.125rem;
      text-align: center;
      color: $blue-15;
      width: 8.5rem;
    }

    .multi-text {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 0.25rem;

      .secondPart {
        color: #6b7280;
        font-size: 0.625rem;
        line-height: 1rem;
        word-break: break-all;
      }
    }

    .icon {
      width: 1rem;
      height: 1rem;

      position: absolute;
      top: 0.5rem;
      right: 0.5rem;

      display: flex;
      justify-content: center;
      align-items: center;

      color: $blue-11;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &.active,
    &:hover,
    &:focus,
    &:active,
    &:focus-within,
    &:focus-visible {
      outline: none;
      border: 0.125rem solid $blue-11;
    }
  }

  &--footer {
    width: 100%;
    height: 1.25rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .back-btn {
      font-weight: $font-weight-medium;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: $gray-15;
      padding: 0;
      height: 1.25rem;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &--indicators {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;

    .indicator {
      width: 0.625rem;
      height: 0.625rem;
      background: $blue-12;
      border-radius: 0.625rem;
      border: none;
      transition: all 0.2s ease;

      &.active {
        width: 1.5625rem;
      }
    }
  }

  &--loader {
    gap: 1.88rem;

    p {
      margin: 0;

      color: $blue-15;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5rem;
    }
  }
}
