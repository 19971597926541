.password-validation-checklist-container {
  width: 100%;
  display: flex;
  margin: 0.25rem 0;

  ul {
    padding: 0;
    list-style: none;
    margin: 0;
    white-space: pre;

    li {
      display: flex;
      align-items: center;
      margin-right: 0.5rem;

      span {
        width: 14px;
        height: 14px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;

        i {
          color: $green-7;

          &.dot {
            font-size: 5px;
            color: $gray-8;
          }
        }
      }

      p {
        margin-bottom: 0;
        color: $gray-7;
      }
    }
  }
}
